<template>
  <b-card
    no-body
    class="mt-1"
  >
    <app-collapse
      accordion
      margin
    >
      <app-collapse-item title="Search">
        <div class="demo-vertical-spacing">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>Doctor</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="doctorFilter"
                :options="doctorOptions"
                :reduce="val => val.id"
                label="name"
                class="w-100"
                :clearable="true"
                @input="(val) => $emit('update:doctorFilter', val)"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <label>Patient</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="patientFilter"
                :options="patientOptions"
                :reduce="val => val.id"
                label="name"
                class="w-100"
                :clearable="true"
                @input="(val) => $emit('update:patientFilter', val)"
              />
            </b-col>
          </b-row>

          <!-- Clear Filter Values -->
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="clearFilterValues"
                >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    doctorOptions: {
      type: Array,
      required: true,
    },
    patientOptions: {
      type: Array,
      required: true,
    },
    doctorFilter: {
      type: Object,
      default: null,
    },
    patientFilter: {
      type: Object,
      default: null,
    },
  },
  methods: {
    clearFilterValues() {
      this.$emit('update:doctorFilter', null)
      this.$emit('update:patientFilter', null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
